//const token = "pk.eyJ1Ijoia2hvbGgiLCJhIjoiY2t4ZDRxd2R0M2dkZDJvbXU5bzRzbmI5dSJ9.Lrxf7iqYogpyBpnwFo9HiQ"; //"pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",

// This Token should be configurable, not hardcoded 
const DEFAULT_TOKEN = process.env.VUE_APP_mapbox_token;


const tileProviders = function (){
  let tileProviders = [
    {
      name: "Greyscale", 
      visible: true, 
      url: getUrl("mapbox/light-v10"),
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }, 
    {
      name: "Satellite", 
      visible: false, 
      url: getUrl("mapbox/satellite-streets-v9"),
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
    }
  ];
  return tileProviders; 
}; 

function getUrl(id, token=DEFAULT_TOKEN) {
  let ret = "https://api.mapbox.com/styles/v1/" + id;
  ret += "/tiles/{z}/{x}/{y}?access_token=" + token;  
  return ret;
}

export {tileProviders}
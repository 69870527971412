<template>
  <div class="w-100 h-100 bg-light d-block map-container">
    <l-map
      ref="map"
      class="h-100 w-100"
      :zoom="zoom"
      :center="center"
      
      @ready="doSomethingOnReady()"
    >

      <l-control-layers position="topright"></l-control-layers>

      <l-tile-layer
        v-for="tileProvider in tileProviders"
        :key="tileProvider.name"
        :name="tileProvider.name"
        :visible="tileProvider.visible"
        :url="tileProvider.url"
        :attribution="tileProvider.attribution"
        layer-type="base"
      />
      <template>
        <l-marker :lat-lng="marker" :icon="getIcon()" />
        <l-circle :lat-lng="center" :radius="radius" color="red" />
        <l-icon-default :image-path="path" />
      </template>
    </l-map>
  </div>
</template>

<style scoped>
.map-container {
  margin: 0;
  padding: 2px;
  display: block;
}
</style>

<script>
import {
  LMap,
  LControlLayers,
  LTileLayer,
  LMarker,
  LIconDefault,
  LCircle,
} from "vue2-leaflet";
import { tileProviders } from "@/lib/geofencing_lib/leafletMapSetup"


const defaultCoord = [49.2700935, -122.985266];

// Layers
// /v1/mapbox/light-v10.h
// Satelite: mapbox/satellite-streets-v11

const getDefault = () => defaultCoord;
export default {
  name: "SimpleLeafletMap",
  components: {
    LMap,
    LControlLayers,
    LTileLayer,
    LMarker,
    LIconDefault,
    LCircle,
  },
  props: {
    center: { type: Array, default: getDefault },
    marker: { type: Array, default: getDefault },
    radius: { type: Number, default: 500}, 
    defaultZoom: { type: Number, default: 13 },
  },
  data() {
    return {
      zoom: 13,
      path: "/images/",
      //bounds:[],
      

      _url: "https://tiles.wmflabs.org/hikebike/{z}/{x}/{y}.png", //"https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      //url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      //url: 	"https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png", // 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      //marker: [47.41322, -1.219482]
      //marker: [49.2700935, -122.985266],
      id: "mapbox/light-v10", //"mapbox/satellite-streets-v11",
      token:
        "pk.eyJ1Ijoia2hvbGgiLCJhIjoiY2t4ZDRxd2R0M2dkZDJvbXU5bzRzbmI5dSJ9.Lrxf7iqYogpyBpnwFo9HiQ", //"pk.eyJ1IjoibWFwYm94IiwiYSI6ImNpejY4NXVycTA2emYycXBndHRqcmZ3N3gifQ.rJcFIG214AriISLbB6B5aw",
      mapOptions: {
        zoomSnap: 1,
        tileSize: 256,
        //scrollWheelZoom: false,
        //draggable: false,
        trackResize: true,
      },
    };
  },
  computed: {
    tileProviders() {
      return tileProviders();
    }
  },
  mounted: function () {
    this.zoom = this.defaultZoom;
  },
  methods: {
    getIcon(color) {
      if (!color) {
        color = "blue";
      }
      let iconUrl = 
        `https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-${color}.png`;
      let icon = new L.Icon({
        iconUrl,
        shadowUrl:
          "https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png",
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowSize: [41, 41],
      });
      return icon;
    },

    doSomethingOnReady() {
      this.$nextTick(() => {
        console.log("Map Loaded");
        this.$refs.map.mapObject.invalidateSize();
        this.$refs.map.mapObject.zoom = this.zoom;

        this.$refs.map.mapObject.mapOptions = this.mapOptions;
      });
    },
  },
};
</script>

<style>
</style>

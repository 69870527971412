var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "w-100 h-100 bg-light d-block map-container" },
    [
      _c(
        "l-map",
        {
          ref: "map",
          staticClass: "h-100 w-100",
          attrs: { zoom: _vm.zoom, center: _vm.center },
          on: {
            ready: function($event) {
              return _vm.doSomethingOnReady()
            }
          }
        },
        [
          _c("l-control-layers", { attrs: { position: "topright" } }),
          _vm._l(_vm.tileProviders, function(tileProvider) {
            return _c("l-tile-layer", {
              key: tileProvider.name,
              attrs: {
                name: tileProvider.name,
                visible: tileProvider.visible,
                url: tileProvider.url,
                attribution: tileProvider.attribution,
                "layer-type": "base"
              }
            })
          }),
          [
            _c("l-marker", {
              attrs: { "lat-lng": _vm.marker, icon: _vm.getIcon() }
            }),
            _c("l-circle", {
              attrs: { "lat-lng": _vm.center, radius: _vm.radius, color: "red" }
            }),
            _c("l-icon-default", { attrs: { "image-path": _vm.path } })
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }